/**
 * Validate Youtube video ID
 * @param youtubeVideoId - Youtube video ID
 */
const validateYoutubeVideoId = (youtubeVideoId: string): string | true => {
    if (youtubeVideoId.length !== 11) {
        return 'Youtube ID must have 11 characters';
    }

    if (
        youtubeVideoId && !/^([a-z0-9_-]{11})?$/i.test(youtubeVideoId)
    ) {
        return 'This is not valid Youtube video ID';
    }

    return true;
};

export default validateYoutubeVideoId;
