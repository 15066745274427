const YOUTUBE_TAGS_MAX_CHARACTER_COUNT = 500;

/**
 * Validate Youtube video tags length
 * @param tags
 */
const validateYoutubeVideoTags = (tags: string): string | true => {
    if (!tags) {
        return true;
    }

    const tagsArray = tags.split(',');

    if (tagsArray.length === 0 || tagsArray[0] === '') {
        return true;
    }

    let characterCounter = 0;

    tagsArray.forEach((tag, index) => {
        if (index !== 0) {
            characterCounter += 1;
        }

        if (tag.indexOf(' ') >= 0) {
            characterCounter += 2;
        }

        characterCounter += tag.length;
    });

    if (characterCounter > YOUTUBE_TAGS_MAX_CHARACTER_COUNT) {
        return `Youtube allows max ${YOUTUBE_TAGS_MAX_CHARACTER_COUNT} characters in all tags`;
    }

    return true;
};

export default validateYoutubeVideoTags;
