import {VideoUploadVersion} from '../../../graphql-types';
import {youtubeIdShortsURLPrefix, youtubeIdURLPrefix} from './youtube';

export const getVideoURLBase = (uploadVersion: VideoUploadVersion) => {
    if (uploadVersion === VideoUploadVersion.Video) {
        return youtubeIdURLPrefix;
    }

    if (uploadVersion === VideoUploadVersion.Shorts) {
        return youtubeIdShortsURLPrefix;
    }

    return '';
};

export const getVideoURL = (videoId: string, uploadVersion: VideoUploadVersion) => `${getVideoURLBase(uploadVersion)}${videoId}`;
