import {ITabsData} from '../../../components/core/tabs/tabs';
import {VideoVersion} from '../../../graphql-types';

const getMutationsTitle = (videoVersion: VideoVersion | undefined) => {
    if (videoVersion === VideoVersion.Translation) {
        return 'Translations';
    }

    if (videoVersion === VideoVersion.VoiceOver) {
        return 'Local versions';
    }

    if (!videoVersion) {
        return 'Translations';
    }

    return '';
};

// eslint-disable-next-line import/prefer-default-export
export const getVideoTabsData = (
    videoVersion: VideoVersion | undefined,
    isSub?: boolean,
): ITabsData[] => [
    {
        id: 'videoBasicInfo',
        title: 'Basic info',
        to: `${isSub ? '.' : ''}./`,
    },
    {
        id: 'videoMutations',
        title: getMutationsTitle(videoVersion),
        to: `${isSub ? '.' : ''}./mutations`,
    },
    {
        id: 'videoChangelog',
        title: 'Changelog',
        to: `${isSub ? '.' : ''}./changelog`,
    },
];
